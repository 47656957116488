import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CountUpModule } from 'ngx-countup';
import { InViewportModule } from '@thisissoon/angular-inviewport';

import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { PortosComponent } from './pages/portos/portos.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MainBannerComponent } from './components/main-banner/main-banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { BrMaskerModule } from 'br-mask';
import { VideosComponent } from './components/videos/videos.component';
import { SectionTextComponent } from './components/section-text/section-text.component';
import { FiliaisComponent } from './components/filiais/filiais.component';
import { ContatoComponent } from './components/contato/contato.component';
import { OuvidoriaComponent } from './components/ouvidoria/ouvidoria.component';
import { ParceiroComponent } from './components/parceiro/parceiro.component';
import { TreemapAscensusComponent } from './components/treemap-ascensus/treemap-ascensus.component';
import { NossasUnidadesComponent } from './components/nossas-unidades/nossas-unidades.component';
import { IconesComponent } from './components/icones/icones.component';
import { QuemSomosComponent } from './components/quem-somos/quem-somos.component';
import { EvolucaoComponent } from './components/evolucao/evolucao.component';
import { DescricaoHistoriaComponent } from './components/descricao-historia/descricao-historia.component';
import { MaioresBrasilComponent } from './components/maiores-brasil/maiores-brasil.component';
import { ComexDistributionComponent } from './components/comex-distribution/comex-distribution.component';
import { ServicosComponent } from './components/servicos/servicos.component';
import { SegmentosComponent } from './components/segmentos/segmentos.component';
import { PlanejamentoComponent } from './components/planejamento/planejamento.component';
import { FinancasComponent } from './components/financas/financas.component';
import { RealStateComponent } from './components/real-state/real-state.component';
import { EnergiaComponent } from './components/energia/energia.component';
import { PortosHomeComponent } from './components/portos-home/portos-home.component';
import { FullCommerceComponent } from './components/full-commerce/full-commerce.component';
import { PortsAreaComponent } from './components/ports-area/ports-area.component';
import { UnidadesComponent } from './components/unidades/unidades.component';
import { GovernancaComplianceComponent } from './components/governanca-compliance/governanca-compliance.component';
import { TrabalheConoscoComponent } from './components/trabalhe-conosco/trabalhe-conosco.component';
import { ComexDistributionFatoComponent } from './components/comex-distribution-fato/comex-distribution-fato.component';
import { LogisticaComponent } from './components/logistica/logistica.component';
import { ComercializacaoComponent } from './components/comercializacao/comercializacao.component';
// import { InvestidoresComponent } from './components/investidores/investidores.component';

const appRoutes: Routes = [
  { path: 'home/af', component: HomeComponent },
  { path: 'home/am', component: HomeComponent },
  { path: 'home/ar', component: HomeComponent },
  { path: 'home/az', component: HomeComponent },
  { path: 'home/be', component: HomeComponent },
  { path: 'home/bg', component: HomeComponent },
  { path: 'home/bn', component: HomeComponent },
  { path: 'home/bs', component: HomeComponent },
  { path: 'home/ca', component: HomeComponent },
  { path: 'home/ceb', component: HomeComponent },
  { path: 'home/co', component: HomeComponent },
  { path: 'home/cs', component: HomeComponent },
  { path: 'home/cy', component: HomeComponent },
  { path: 'home/da', component: HomeComponent },
  { path: 'home/de', component: HomeComponent },
  { path: 'home/el', component: HomeComponent },
  { path: 'home/en', component: HomeComponent },
  { path: 'home/eo', component: HomeComponent },
  { path: 'home/es', component: HomeComponent },
  { path: 'home/et', component: HomeComponent },
  { path: 'home/eu', component: HomeComponent },
  { path: 'home/fa', component: HomeComponent },
  { path: 'home/fi', component: HomeComponent },
  { path: 'home/fr', component: HomeComponent },
  { path: 'home/fy', component: HomeComponent },
  { path: 'home/ga', component: HomeComponent },
  { path: 'home/gd', component: HomeComponent },
  { path: 'home/gl', component: HomeComponent },
  { path: 'home/gu', component: HomeComponent },
  { path: 'home/ha', component: HomeComponent },
  { path: 'home/haw', component: HomeComponent },
  { path: 'home/hi', component: HomeComponent },
  { path: 'home/hmn', component: HomeComponent },
  { path: 'home/hr', component: HomeComponent },
  { path: 'home/ht', component: HomeComponent },
  { path: 'home/hu', component: HomeComponent },
  { path: 'home/hy', component: HomeComponent },
  { path: 'home/id', component: HomeComponent },
  { path: 'home/ig', component: HomeComponent },
  { path: 'home/is', component: HomeComponent },
  { path: 'home/it', component: HomeComponent },
  { path: 'home/iw', component: HomeComponent },
  { path: 'home/ja', component: HomeComponent },
  { path: 'home/jw', component: HomeComponent },
  { path: 'home/ka', component: HomeComponent },
  { path: 'home/kk', component: HomeComponent },
  { path: 'home/km', component: HomeComponent },
  { path: 'home/kn', component: HomeComponent },
  { path: 'home/ko', component: HomeComponent },
  { path: 'home/ku', component: HomeComponent },
  { path: 'home/ky', component: HomeComponent },
  { path: 'home/la', component: HomeComponent },
  { path: 'home/lb', component: HomeComponent },
  { path: 'home/lo', component: HomeComponent },
  { path: 'home/lt', component: HomeComponent },
  { path: 'home/lv', component: HomeComponent },
  { path: 'home/mg', component: HomeComponent },
  { path: 'home/mi', component: HomeComponent },
  { path: 'home/mk', component: HomeComponent },
  { path: 'home/ml', component: HomeComponent },
  { path: 'home/mn', component: HomeComponent },
  { path: 'home/mr', component: HomeComponent },
  { path: 'home/ms', component: HomeComponent },
  { path: 'home/mt', component: HomeComponent },
  { path: 'home/my', component: HomeComponent },
  { path: 'home/ne', component: HomeComponent },
  { path: 'home/nl', component: HomeComponent },
  { path: 'home/no', component: HomeComponent },
  { path: 'home/ny', component: HomeComponent },
  { path: 'home/or', component: HomeComponent },
  { path: 'home/pa', component: HomeComponent },
  { path: 'home/pl', component: HomeComponent },
  { path: 'home/pt-br', component: HomeComponent },
  { path: 'home/ps', component: HomeComponent },
  { path: 'home/ro', component: HomeComponent },
  { path: 'home/ru', component: HomeComponent },
  { path: 'home/rw', component: HomeComponent },
  { path: 'home/sd', component: HomeComponent },
  { path: 'home/si', component: HomeComponent },
  { path: 'home/sk', component: HomeComponent },
  { path: 'home/sl', component: HomeComponent },
  { path: 'home/sm', component: HomeComponent },
  { path: 'home/sn"', component: HomeComponent },
  { path: 'home/so', component: HomeComponent },
  { path: 'home/sq', component: HomeComponent },
  { path: 'home/sr', component: HomeComponent },
  { path: 'home/st', component: HomeComponent },
  { path: 'home/su', component: HomeComponent },
  { path: 'home/sv"', component: HomeComponent },
  { path: 'home/sw', component: HomeComponent },
  { path: 'home/ta', component: HomeComponent },
  { path: 'home/te', component: HomeComponent },
  { path: 'home/tg', component: HomeComponent },
  { path: 'home/th', component: HomeComponent },
  { path: 'home/tk', component: HomeComponent },
  { path: 'home/tl', component: HomeComponent },
  { path: 'home/tr', component: HomeComponent },
  { path: 'home/tt', component: HomeComponent },
  { path: 'home/ug', component: HomeComponent },
  { path: 'home/uk', component: HomeComponent },
  { path: 'home/ur', component: HomeComponent },
  { path: 'home/uz', component: HomeComponent },
  { path: 'home/vi', component: HomeComponent },
  { path: 'home/xh', component: HomeComponent },
  { path: 'home/yi', component: HomeComponent },
  { path: 'home/yo', component: HomeComponent },
  { path: 'home/zh-CN', component: HomeComponent },
  { path: 'home/zh-TW', component: HomeComponent },
  { path: 'home/zu', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'portos', component: PortosComponent },
  { path: 'portos/af', component: PortosComponent },
  { path: 'portos/am', component: PortosComponent },
  { path: 'portos/ar', component: PortosComponent },
  { path: 'portos/az', component: PortosComponent },
  { path: 'portos/be', component: PortosComponent },
  { path: 'portos/bg', component: PortosComponent },
  { path: 'portos/bn', component: PortosComponent },
  { path: 'portos/bs', component: PortosComponent },
  { path: 'portos/ca', component: PortosComponent },
  { path: 'portos/ceb', component: PortosComponent },
  { path: 'portos/co', component: PortosComponent },
  { path: 'portos/cs', component: PortosComponent },
  { path: 'portos/cy', component: PortosComponent },
  { path: 'portos/da', component: PortosComponent },
  { path: 'portos/de', component: PortosComponent },
  { path: 'portos/el', component: PortosComponent },
  { path: 'portos/en', component: PortosComponent },
  { path: 'portos/eo', component: PortosComponent },
  { path: 'portos/es', component: PortosComponent },
  { path: 'portos/et', component: PortosComponent },
  { path: 'portos/eu', component: PortosComponent },
  { path: 'portos/fa', component: PortosComponent },
  { path: 'portos/fi', component: PortosComponent },
  { path: 'portos/fr', component: PortosComponent },
  { path: 'portos/fy', component: PortosComponent },
  { path: 'portos/ga', component: PortosComponent },
  { path: 'portos/gd', component: PortosComponent },
  { path: 'portos/gl', component: PortosComponent },
  { path: 'portos/gu', component: PortosComponent },
  { path: 'portos/ha', component: PortosComponent },
  { path: 'portos/haw', component: PortosComponent },
  { path: 'portos/hi', component: PortosComponent },
  { path: 'portos/hmn', component: PortosComponent },
  { path: 'portos/hr', component: PortosComponent },
  { path: 'portos/ht', component: PortosComponent },
  { path: 'portos/hu', component: PortosComponent },
  { path: 'portos/hy', component: PortosComponent },
  { path: 'portos/id', component: PortosComponent },
  { path: 'portos/ig', component: PortosComponent },
  { path: 'portos/is', component: PortosComponent },
  { path: 'portos/it', component: PortosComponent },
  { path: 'portos/iw', component: PortosComponent },
  { path: 'portos/ja', component: PortosComponent },
  { path: 'portos/jw', component: PortosComponent },
  { path: 'portos/ka', component: PortosComponent },
  { path: 'portos/kk', component: PortosComponent },
  { path: 'portos/km', component: PortosComponent },
  { path: 'portos/kn', component: PortosComponent },
  { path: 'portos/ko', component: PortosComponent },
  { path: 'portos/ku', component: PortosComponent },
  { path: 'portos/ky', component: PortosComponent },
  { path: 'portos/la', component: PortosComponent },
  { path: 'portos/lb', component: PortosComponent },
  { path: 'portos/lo', component: PortosComponent },
  { path: 'portos/lt', component: PortosComponent },
  { path: 'portos/lv', component: PortosComponent },
  { path: 'portos/mg', component: PortosComponent },
  { path: 'portos/mi', component: PortosComponent },
  { path: 'portos/mk', component: PortosComponent },
  { path: 'portos/ml', component: PortosComponent },
  { path: 'portos/mn', component: PortosComponent },
  { path: 'portos/mr', component: PortosComponent },
  { path: 'portos/ms', component: PortosComponent },
  { path: 'portos/mt', component: PortosComponent },
  { path: 'portos/my', component: PortosComponent },
  { path: 'portos/ne', component: PortosComponent },
  { path: 'portos/nl', component: PortosComponent },
  { path: 'portos/no', component: PortosComponent },
  { path: 'portos/ny', component: PortosComponent },
  { path: 'portos/or', component: PortosComponent },
  { path: 'portos/pa', component: PortosComponent },
  { path: 'portos/pl', component: PortosComponent },
  { path: 'portos/pt-br', component: PortosComponent },
  { path: 'portos/ps', component: PortosComponent },
  { path: 'portos/ro', component: PortosComponent },
  { path: 'portos/ru', component: PortosComponent },
  { path: 'portos/rw', component: PortosComponent },
  { path: 'portos/sd', component: PortosComponent },
  { path: 'portos/si', component: PortosComponent },
  { path: 'portos/sk', component: PortosComponent },
  { path: 'portos/sl', component: PortosComponent },
  { path: 'portos/sm', component: PortosComponent },
  { path: 'portos/sn"', component: PortosComponent },
  { path: 'portos/so', component: PortosComponent },
  { path: 'portos/sq', component: PortosComponent },
  { path: 'portos/sr', component: PortosComponent },
  { path: 'portos/st', component: PortosComponent },
  { path: 'portos/su', component: PortosComponent },
  { path: 'portos/sv"', component: PortosComponent },
  { path: 'portos/sw', component: PortosComponent },
  { path: 'portos/ta', component: PortosComponent },
  { path: 'portos/te', component: PortosComponent },
  { path: 'portos/tg', component: PortosComponent },
  { path: 'portos/th', component: PortosComponent },
  { path: 'portos/tk', component: PortosComponent },
  { path: 'portos/tl', component: PortosComponent },
  { path: 'portos/tr', component: PortosComponent },
  { path: 'portos/tt', component: PortosComponent },
  { path: 'portos/ug', component: PortosComponent },
  { path: 'portos/uk', component: PortosComponent },
  { path: 'portos/ur', component: PortosComponent },
  { path: 'portos/uz', component: PortosComponent },
  { path: 'portos/vi', component: PortosComponent },
  { path: 'portos/xh', component: PortosComponent },
  { path: 'portos/yi', component: PortosComponent },
  { path: 'portos/yo', component: PortosComponent },
  { path: 'portos/zh-CN', component: PortosComponent },
  { path: 'portos/zh-TW', component: PortosComponent },
  { path: 'portos/zu', component: PortosComponent },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PortosComponent,
    NavbarComponent,
    MainBannerComponent,
    FooterComponent,
    PageNotFoundComponent,
    VideosComponent,
    SectionTextComponent,
    FiliaisComponent,
    ContatoComponent,
    OuvidoriaComponent,
    ParceiroComponent,
    TreemapAscensusComponent,
    NossasUnidadesComponent,
    IconesComponent,
    QuemSomosComponent,
    EvolucaoComponent,
    DescricaoHistoriaComponent,
    MaioresBrasilComponent,
    ComexDistributionComponent,
    ServicosComponent,
    SegmentosComponent,
    PlanejamentoComponent,
    FinancasComponent,
    RealStateComponent,
    EnergiaComponent,
    PortosHomeComponent,
    FullCommerceComponent,
    PortsAreaComponent,
    UnidadesComponent,
    GovernancaComplianceComponent,
    TrabalheConoscoComponent,
    ComexDistributionFatoComponent,
    LogisticaComponent,
    ComercializacaoComponent,
    // InvestidoresComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrMaskerModule,
    FormsModule,
    CountUpModule,
    InViewportModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true }
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
